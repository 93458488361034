import React from 'react';
import {ProgressBar, LegacyStack} from '@shopify/polaris';
import './migrationProgressBar.css';

const MigrationProgressBar = ({styles, progress, isLoading}) => {
  return (
    <div style={styles} className={!isLoading ? 'isNotVisible' : undefined}>
      <LegacyStack distribution='fill'>
        <ProgressBar progress={progress} />
      </LegacyStack>
    </div>
  );
};

export default MigrationProgressBar;